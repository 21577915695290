@font-face {
    font-family: 'AvenirMedium';
    src: url('../assets/AvenirNextRoundedStd-Med.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirDemi';
    src: url('../assets/AvenirNextRoundedStd-Demi.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirBold';
    src: url('../assets/AvenirNextRoundedStd-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
html, a{
    color: white;
}
html, a{
    font-family: AvenirMedium;
}
h2, select, button{
    font-family:AvenirDemi;
}
h1{
    font-family: AvenirBold;
}
body{
    background-color: #3B2400;
}
footer{
    font-size: 80%;
}
a{
    text-decoration: none;
    display: flex;
    align-items: center;
}
header{
    display: flex;
    height: 10vh;
    align-items: center;
    background-color: #E4941B;
    padding: 10px;
    border-radius: 10px;
    position: fixed;
    left: 4vmin;
    right: 4vmin;
    border: 3px solid white;
    z-index: 100;
}
.spacer{
    flex-grow: 1;
}

/* From Uiverse.io by PhyoTP 
.label-check {
    display: none;
}
 

.hamburger-label {
    border: none;
    background-color: #E4941B;
    width: 5vw;
    height: 5vh;
    display: block;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 20px;
}
 
.hamburger-label div {
    width: 5vw;
    height: 1vh;
    background-color: #fff;
    position: absolute;
}
 
.line1 {
    transition: all 0.3s;
}
  
.line2 {
    margin: 2vmin 0 0 0;
    transition: 0.3s;
}
  
.line3 {
    margin: 4vmin 0 0 0;
    transition: 0.3s;
}
  
#label-check:checked + .hamburger-label .line1 {
    transform: rotate(35deg) scaleX(0.55) translate(3.9vmin, -4.5px);
    border-radius: 50px 50px 50px 0;
}
  
#label-check:checked + .hamburger-label .line3 {
    transform: rotate(-35deg) scaleX(0.55) translate(3.9vmin, 4.5px);
    border-radius: 0 50px 50px 50px;
}
  
#label-check:checked + .hamburger-label .line2 {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    width: 4.5vw;
}
#label-check:checked + .hamburger-label {
    transform: rotate(90deg);
    position: relative;
    top: 0;
    right: 0.5vw;
}*/
nav{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
nav a{
    background-color: #E4941B;
    color: white;
    padding: 0.5rem;
    border-radius: 10px;
    border: 5px solid white;
    transition: all 0.5s;
    margin-right: 0.5rem;
}
nav a:hover{
    color: #E4941B;
    background-color: white;
}
.stuff {
    background-color: #E4941B;
    border-radius: 10px;
    padding:1vmin;
    padding-left: 3vmin;
    padding-right: 3vmin;
    text-decoration: none;  /* To remove the underline */
    color: white;  /* Change text color */
    display: flex;  /* Ensures padding and background apply */
    margin: 3vh;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
td, th {
    text-align: center;
    border: 1px solid white;
}
#icon{
    height: 8vh;
    width: 8vh;
    border-radius: 10vh;
    border: 5px solid #fff;
    margin: 1rem;
}
select{
    background-color: #E4941B;
    color: white;
    border: 5px solid white;
    border-radius: 10px;
    padding: 0.5rem;
    margin: 1rem;
}
#spin {
    width: 80vmin;
    height: 80vmin;
    animation: spin 20s linear infinite;
    transition: all 1s ease-in-out;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#spin:hover {
    width: 90vmin;
    height: 90vmin;
}

#spinSection{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
}
footer {
    background-color: #E4941B;
    border-radius: 10px;
    padding:1vmin;
    padding-left: 3vmin;
    padding-right: 3vmin;
    text-decoration: none;  /* To remove the underline */
    color: white;  /* Change text color */
    display: flex;  /* Ensures padding and background apply */
    margin: 3vh;
    text-align: center;
    flex-direction:column;
}
#stack {
    margin-inline: auto;
    max-width: fit-content;
    position: relative; /* Ensure it acts as a reference for absolute positioning */
    bottom: 15%;
}

#stack .flip-card {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, 10%); /* Centers the button horizontally */
}

#flashcards{
    display: flex;
    flex-direction: row;
}

.flashcard-buttons {
    border: none;
    width: 10vw;
    border-radius: 2rem;
    cursor: pointer;
}
#know{
    background-color: limegreen;
}
#dunno{
    background-color: red;
}

#flashcards-undo{
    border: none;
    background: none;
    color: white;
    font-size: 150%;
    cursor: pointer;
}
.square {
    background-color: #E4941B;
    border-radius: 10px;
    text-decoration: none;  /* To remove the underline */
    color: white;  /* Change text color */
    display: flex;  /* Ensures padding and background apply */
    margin: 5vmin;
    text-align: center;
    flex-direction: column;
    align-items: center;
    width: 40vmin; /* Set a fixed width */
    height: 40vmin; /* Set the height equal to the width */
    justify-content: center; /* Center content vertically */
    box-shadow: 5px 10px #fff;
    transition: all 0.5s;
}
#feed{
    display: flex;
    flex-wrap: wrap;
    margin: 5vmin;
}
.square:hover{
    box-shadow: -5px 10px #fff;
}